import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MoodyManBCG from '../images/moodyman_background.png';
import ERS from '../images/ERS.jpg';


const FeaturedProjects = () => {
  return (
    <div className="mb-6">
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child box">
            <a href="https://marketplace.visualstudio.com/items?itemName=ShishirSingh.easy-react-snippet" target="_blank" rel="noopener">
              <p class="title">Easy React Snippet <span class="tag is-info is-light is-medium">
                Visual Studio Code Extension
              </span></p>
              <p class="subtitle">Intuitive React templates that make it easier to enter repeating code patterns using Intellisense </p>
              <figure class="image is-2by1">
                <img src={ERS} alt="easy-react-snippet-logo" />
              </figure>
            </a>
          </div>
          <div class="tile is-child is-flex-grow-0 p-2"></div>
          <div class="tile is-child box">
            <a href="https://moodyman.gamelette.com/" target="_blank" rel="noopener">
              <p class="title">
                Moody Man
                <span class="tag is-info is-light is-medium">Game</span>
              </p>
              <p class="subtitle">Play a game of hangman online with some of the hardest words to guess under different categories.</p>
              <figure class="image is-2by1">
                <img src={MoodyManBCG} alt="moody man game screenshot" />
              </figure>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
const PersonalProjects = () => {
  return (
    <>
      <h3 className="mt-2 is-size-3 has-text-centered-mobile">
        Personal Projects
      </h3>
      <hr />
      <FeaturedProjects />
      <div className="block">
        <h4 className="is-size-5 has-text-weight-bold">
          this.website = () =&gt; shishirsingh.com
        </h4>
        <span className="content">
          <ul>
            <li>
              Responsive website built on React Frameowrk
              <a
                href="http://gatsbyjs.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {` Gatsby `}
              </a>
              and
              <a
                href="https://bulma.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {` Bulma CSS Framework `}
              </a>
              using <em>SASS</em>
            </li>
            <li>
              Exported to Static Site and hosted on
              <a
                href="https://www.netlify.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {` Netlify Servers`}
              </a>
            </li>
            <li>
              Have Analytics, SEO built in and{" "}
              <em>will continue to improve...</em>
            </li>
          </ul>
        </span>
      </div>
      <div className="block">
        <h4 className="is-size-5 has-text-weight-bold">
          AQI – Air Quality Index
        </h4>
        <span className="content">
          <ul>
            <li>
              Progressive Web App for fetching the location of the user and
              displaying the air quality index,
            </li>
            <li>
              The App is installable on multiple platforms such as
              Windows/Android/Mac.
            </li>
            <li>
              The data for air quality is fetched from OpenAQ using Restful API.
            </li>
            <li>Tech Used: HTML, CSS and JavaScript </li>
            <li>
              <a
                href="https://stupefied-turing-e4e6f3.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <em>
                  URL (data fetch not working due to changes in public apis)
                </em>
              </a>
            </li>
          </ul>
        </span>
      </div>
      <div className="block">
        <h4 className="is-size-5 has-text-weight-bold">
          Secret Message Android App
        </h4>
        <span className="content">
          <ul>
            <li>
              Encrypt the message with password using AES and Base64 encoding
              for similar size.
            </li>
            <li>
              Share messages using SMS, WhatsApp, or other sharing platforms.
            </li>
          </ul>
        </span>
      </div>
      <div className="block">
        <h4 className="is-size-5 has-text-weight-bold">
          Yonder – Distributed Media Encoder
        </h4>
        <span className="content">
          <ul>
            <li>
              A Java GUI application that uses compute power of all PCs in{" "}
              <a
                href="https://en.wikipedia.org/wiki/Local_area_network"
                target="_blank"
                rel="noopener noreferrer"
              >
                <em>lan</em>
              </a>{" "}
              to encode/decode media formats quickly
            </li>
            <li>
              The main modules are peer discovery; file transfer mechanism;
              encoder interfacing and graphics.
            </li>
            <li>
              Implemented with Java, FFMPEG libraries, and Windows Builder
              Eclipse plug-in.
            </li>
            <li>Uses multithreading and networking concepts.</li>
          </ul>
        </span>
      </div>
      <div className="block">
        <h4 className="is-size-5 has-text-weight-bold">Other Projects</h4>
        <span className="content">
          <ul>
            <li>
              <code>
                <a
                  href="https://www.interviewreact.com/"
                  target="_blank"
                  rel="noopener"
                >
                  Interview React
                </a>
              </code>
            </li>
            <li>
              <code>
                <a
                  href="https://decks.shishirsingh.com/"
                  target="_blank"
                  rel="noopener"
                >
                  React Decks/Presentation
                </a>
              </code>
            </li>
          </ul>
        </span>
      </div>
    </>
  )
}
/* 
const ComapnyProjects = () => {
  return (
    <>
      <h3 className="is-size-3">Comapny Projects</h3>
      <div className="block">
        <h4 className="is-size-5">
          Workers Compensation (Cigna/Express Scripts)
        </h4>
        <p>
          Worked as tech lead for the project, managing a team of 8-10 people,
          such as full stack designers and front-end developers. o Developed
          mymatrixx.com website. o Worked with business and design requirements
          to develop web pages that adhered to company, industry, and usability
          standards.
        </p>
      </div>
      <div className="block">
        <h4 className="is-size-5">
          DCRS Drug Coverage Rule Station (Cigna/Express Scripts)
        </h4>
        <p>
          Designed UI mockups for new drug coverage migration screens. o
          Bootstrapped project, define standards and design principles. o
          Developed initial screens and functionality with react and redux
          library.
        </p>
      </div>
      <div className="block">
        <h4 className="is-size-5">GPS – Global Procurement System (TCS)</h4>
        <p>
          Re-engineering of the JavaEE application using Bootstrap, AngularJS,
          and Spring, and Hibernate. o Full Stack design development according
          to client-set feature priorities. o Created batches for automating
          processes. Interfacing between IBM WPS and Java System. o Reduced
          daily errors by 80%, from 15000 to 3000.
        </p>
      </div>
      <div className="block">
        <h4 className="is-size-5">FMS – Facility management System (TCS) </h4>
        <p>
          The Software enables the organization to manage seat inventory and
          occupancy, also for organizing various license and agreement details
          of different facilities. o Responsible for handling end-to-end project
          execution from requirement gathering to production deployment. o
          Implemented module to generate dynamic reports based on different
          parameters.
        </p>
      </div>
      <div className="block">
        <h4 className="is-size-5">
          GROSS – Global Repository of Secured Storage (TCS)
        </h4>
        <p>
          <ul>
            <li>
              The system was designed to archive files related to purchase-order
              and invoices.
            </li>
            <li>
              Maintained and Developed Code for Alfresco Document Management
              System.
            </li>
            <li>Improved file upload speed by up to 12 seconds per file. </li>
            <li>
              Designed and Implemented performance monitoring tools to monitor
              real-time performance and logging for failure and its causes.
            </li>
          </ul>
        </p>
      </div>
    </>
  );
}; */
const Portfolio = () => {
  return (
    <Layout>
      <Seo title="Shishir Singh Portfolio and Projects" />
      <div id="porfolio" className="container section portfolio">
        <div className="section-heading has-text-centered">
          <h3 className="title is-2">Portfolio</h3>
          <h4 className="subtitle is-5">My latest works</h4>
        </div>
        <PersonalProjects />
        {/*  <ComapnyProjects /> */}
      </div>
    </Layout>
  )
}
export default Portfolio
